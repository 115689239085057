<template>
    <div class="card box widget-social pt-2 pb-2" :class="componentStyle">
        <widgetTitleBar :moduleData="this.moduleData" :widgetInfo="content" :alarmList="this.alarmList" :menu-items="this.items" @menu-click="($value) => this.toggle($value)"></widgetTitleBar>
        <OverlayPanel
            class="param-overlay"
            ref="alarmOverlay"
            appendTo="body"
            :autoZIndex="true"
            @show="focusElement" 
            :dismissable="true"
            :showCloseIcon="true"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: 'auto' }"
        >
            <div v-for="(element) in this.alarmList" :key="element.label">
                <span>🔴 {{ element.label }}</span>
            </div>
        </OverlayPanel>
        <div v-if="isLoading">
            <loader-skeleton :widgetInfo="content"></loader-skeleton>
        </div>
        <OverlayPanel class="param-overlay" ref="periods" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>

        <OverlayPanel ref="misc" appendTo="body" @show="focusElement" :dismissable="false">
        </OverlayPanel>
        <Dialog
            v-model:visible="showDiagMisc"
            @show="focusElement"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '700px' }"
        >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i><span class="subtext mt-1">{{ moduleDepartment.key }} {{ moduleDepartment.label }} ⚡ Allgemeine Einstellungen</span></h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('misc')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView :scrollable="true">
                    <TabPanel header="Steuerung">
                        <Panel header="Druckluft" class="mt-0">
                            <template #icons>
                                <i class="fi fi-rr-wind bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('enablePressureControl').value" /><span class="ml-2">{{ this.getDiagDataCfg('enablePressureControl').label }}</span>
                                        </div>
                                    </div>
                                    <InlineMessage severity="info" class="mt-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }" v-if="this.getDiagDataCfg('enablePressureControl').value">
                                        Bei einer verriegelten Störung mit Abschaltung muss die Störung in der Alarmliste quittiert werden, der Hauptschalter deaktiviert (speichern) und nach 5 Sekunden wieder aktiviert werden!
                                    </InlineMessage>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataCfg('enablePressureControl').value">
                                <div class="field col">
                                    <span class="p-float-label mr-2 mt-2">
                                        <InputNumber id="pressureSetOnPlus" ref="pressureSetOnPlus" :min="0" :max="10"
                                            v-model="getDiagDataCfg('pressureSetOnPlus').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('pressureSetOnPlus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="pressureSetOnPlus" class="mr-2">{{ getDiagDataCfg('pressureSetOnPlus').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2 mt-2">
                                        <InputNumber id="pressureSetOffPlus" ref="pressureSetOffPlus" :min="0" :max="10"
                                            v-model="getDiagDataCfg('pressureSetOffPlus').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('pressureSetOffPlus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="pressureSetOffPlus" class="mr-2">{{ getDiagDataCfg('pressureSetOffPlus').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataCfg('enablePressureControl').value">
                                <div class="field col">
                                    <span class="p-float-label mr-2 mt-2">
                                        <InputNumber id="pressureSetOnMinus" ref="pressureSetOnMinus" :min="0" :max="10"
                                            v-model="getDiagDataCfg('pressureSetOnMinus').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('pressureSetOnMinus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="pressureSetOnMinus" class="mr-2">{{ getDiagDataCfg('pressureSetOnMinus').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2 mt-2">
                                        <InputNumber id="pressureSetOffMinus" ref="pressureSetOffMinus" :min="0" :max="10"
                                            v-model="getDiagDataCfg('pressureSetOffMinus').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('pressureSetOffMinus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="pressureSetOffMinus" class="mr-2">{{ getDiagDataCfg('pressureSetOffMinus').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Wasser" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-water bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('enableLevelControl').value" /><span class="ml-2">{{ this.getDiagDataCfg('enableLevelControl').label }}</span>
                                        </div>
                                    </div>
                                    <InlineMessage severity="info" class="mt-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }" v-if="this.getDiagDataCfg('enableLevelControl').value">
                                        Bei einer verriegelten Störung mit Abschaltung muss die Störung in der Alarmliste quittiert werden, der Hauptschalter deaktiviert (speichern) und nach 5 Sekunden wieder aktiviert werden!
                                    </InlineMessage>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataCfg('enableLevelControl').value">
                                <div class="field col">
                                    <span class="p-float-label mr-2 mt-2">
                                        <InputNumber id="waterSetOnPlus" ref="waterSetOnPlus" :min="0" :max="100"
                                            v-model="getDiagDataCfg('waterSetOnPlus').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('waterSetOnPlus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="waterSetOnPlus" class="mr-2">{{ getDiagDataCfg('waterSetOnPlus').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2 mt-2">
                                        <InputNumber id="waterSetOffPlus" ref="waterSetOffPlus" :min="0" :max="100"
                                            v-model="getDiagDataCfg('waterSetOffPlus').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('waterSetOffPlus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="waterSetOffPlus" class="mr-2">{{ getDiagDataCfg('waterSetOffPlus').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataCfg('enableLevelControl').value">
                                <div class="field col">
                                    <span class="p-float-label mr-2 mt-2">
                                        <InputNumber id="waterSetOnMinus" ref="waterSetOnMinus" :min="0" :max="100"
                                            v-model="getDiagDataCfg('waterSetOnMinus').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('waterSetOnMinus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="waterSetOnMinus" class="mr-2">{{ getDiagDataCfg('waterSetOnMinus').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2 mt-2">
                                        <InputNumber id="waterSetOffMinus" ref="waterSetOffMinus" :min="0" :max="100"
                                            v-model="getDiagDataCfg('waterSetOffMinus').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('waterSetOffMinus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="waterSetOffMinus" class="mr-2">{{ getDiagDataCfg('waterSetOffMinus').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Alarme Druck">
                        <Panel header="Alarmeinstellungen Minimum-Druck">
                            <template #icons>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmPressureMin" ref="alarmPressureMin" :min="0" :max="100"
                                            v-model="getDiagDataCfg('alarmPressureMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmPressureMin').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmPressureMin" class="mr-2">{{ getDiagDataCfg('alarmPressureMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmPressureMinPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen Maximum-Druck" class="">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmPressureMax" ref="alarmPressureMax" :min="0" :max="100"
                                            v-model="getDiagDataCfg('alarmPressureMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmPressureMax').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmPressureMax" class="mr-2">{{ getDiagDataCfg('alarmPressureMax').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmPressureMaxPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen Druck Alarmverzögerung" class="">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmPressureDiff" ref="alarmPressureDiff" :min="0" :max="100"
                                            v-model="getDiagDataCfg('alarmPressureDiff').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmPressureDiff').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmPressureDiff" class="mr-2">{{ getDiagDataCfg('alarmPressureDiff').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="alarmPressureDelay" ref="alarmPressureDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('alarmPressureDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmPressureDelay" class="mr-2">{{ this.getDiagDataCfg('alarmPressureDelay').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen Drucknachspeisung Zeit zu lange" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="alarmPressurePlusDelay" ref="alarmPressurePlusDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('alarmPressurePlusDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmPressurePlusDelay" class="mr-2">{{ this.getDiagDataCfg('alarmPressurePlusDelay').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmPressurePlusPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen Druckabsenkung Zeit zu lange" class="">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="alarmPressureMinusDelay" ref="alarmPressureMinusDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('alarmPressureMinusDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmPressureMinusDelay" class="mr-2">{{ this.getDiagDataCfg('alarmPressureMinusDelay').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmPressureMinusPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Alarme Inhalt">
                        <Panel header="Alarmeinstellungen Minimum-Inhalt" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmLevelMin" ref="alarmLevelMin" :min="0" :max="100"
                                            v-model="getDiagDataCfg('alarmLevelMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmLevelMin').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmLevelMin" class="mr-2">{{ getDiagDataCfg('alarmLevelMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmLevelMinPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen Maximum-Inhalt" class="">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmLevelMax" ref="alarmLevelMax" :min="0" :max="100"
                                            v-model="getDiagDataCfg('alarmLevelMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmLevelMax').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmLevelMax" class="mr-2">{{ getDiagDataCfg('alarmLevelMax').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmLevelMaxPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen Inhalt Alarmverzögerung" class="">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmLevelDiff" ref="alarmLevelDiff" :min="0" :max="100"
                                            v-model="getDiagDataCfg('alarmLevelDiff').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmLevelDiff').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmLevelDiff" class="mr-2">{{ getDiagDataCfg('alarmLevelDiff').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="alarmLevelDelay" ref="alarmLevelDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('alarmLevelDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmLevelDelay" class="mr-2">{{ this.getDiagDataCfg('alarmLevelDelay').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen Wassernachspeisung Zeit zu lange" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="alarmWaterPlusDelay" ref="alarmWaterPlusDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('alarmWaterPlusDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmWaterPlusDelay" class="mr-2">{{ this.getDiagDataCfg('alarmWaterPlusDelay').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmWaterPlusPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen Wasserabsenkung Zeit zu lange" class="">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="alarmWaterMinusDelay" ref="alarmWaterMinusDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('alarmWaterMinusDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmWaterMinusDelay" class="mr-2">{{ this.getDiagDataCfg('alarmWaterMinusDelay').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmWaterMinusPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Sensoreinstellungen" v-if="isMaster">
                        <Panel header="Filtereinstellungen" class="mt-0">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tempFilterTime" ref="tempFilterTime" :min="0" :max="100000"
                                                v-model="this.getDiagDataCfg('tempFilterTime').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataCfg('tempFilterTime').unit}`"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tempFilterTime" class="mr-2">{{ this.getDiagDataCfg('tempFilterTime').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Sensorskalierung Druck" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-cog bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pressureSensInMin" ref="pressureSensInMin" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('pressureSensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pressureSensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pressureSensOutMin" ref="pressureSensOutMin" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('pressureSensOutMin').value"
                                                :suffix="` ${this.getDiagDataCfg('pressureSensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pressureSensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pressureSensInMax" ref="pressureSensInMax" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('pressureSensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pressureSensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pressureSensOutMax" ref="pressureSensOutMax" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('pressureSensOutMax').value"
                                                :suffix="` ${this.getDiagDataCfg('pressureSensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pressureSensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pressureSensOffset" ref="pressureSensOffset" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('pressureSensOffset').value"
                                                :suffix="` ${this.getDiagDataCfg('pressureSensOffset').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pressureSensOffset" class="mr-2">Sensor Offset</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center mt-3">
                                            <InputSwitch v-model="this.getDiagDataCfg('pressureSensEnable').value" /><span class="ml-2">{{ this.getDiagDataCfg('pressureSensEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Sensorskalierung Level" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-cog bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="levelSensInMin" ref="levelSensInMin" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('levelSensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="levelSensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="levelSensOutMin" ref="levelSensOutMin" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('levelSensOutMin').value"
                                                :suffix="` ${this.getDiagDataCfg('levelSensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="levelSensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="levelSensInMax" ref="levelSensInMax" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('levelSensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="levelSensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="levelSensOutMax" ref="levelSensOutMax" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('levelSensOutMax').value"
                                                :suffix="` ${this.getDiagDataCfg('levelSensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="levelSensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="levelSensOffset" ref="levelSensOffset" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('levelSensOffset').value"
                                                :suffix="` ${this.getDiagDataCfg('levelSensOffset').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="levelSensOffset" class="mr-2">Sensor Offset</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center mt-3">
                                            <InputSwitch v-model="this.getDiagDataCfg('levelSensEnable').value" /><span class="ml-2">{{ this.getDiagDataCfg('levelSensEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="levelSensWater" ref="levelSensWater" :min="0" :max="10000"
                                                v-model="this.getDiagDataCfg('levelSensWater').value"
                                                :suffix="` ${this.getDiagDataCfg('levelSensWater').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="levelSensWater" class="mr-2">{{ this.getDiagDataCfg('levelSensWater').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Service" v-if="isMaster">
                        <Panel header="Service" class="mt-0">
                            <template #icons>
                                <i class="fi fi-rr-heat bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="enableDelay" ref="enableDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('enableDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="enableDelay" class="mr-2">{{ this.getDiagDataCfg('enableDelay').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="waterMinDelay" ref="waterMinDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('waterMinDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="waterMinDelay" class="mr-2">{{ this.getDiagDataCfg('waterMinDelay').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Wassermangel Pegel" class="mt-2">
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center mt-3">
                                            <InputSwitch v-model="this.getDiagDataCfg('extAlarmLogic').value" /><span class="ml-2">{{ this.getDiagDataCfg('extAlarmLogic').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Externe Alarmeinstellungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="alarmExternDelay" ref="alarmExternDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('alarmExternDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmExternDelay" class="mr-2">{{ this.getDiagDataCfg('alarmExternDelay').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmExternPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Wassermangel Alarmeinstellungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="alarmWmDelay" ref="alarmWmDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('alarmWmDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmWmDelay" class="mr-2">{{ this.getDiagDataCfg('alarmWmDelay').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmWmPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Alarmausgang" v-if="isMaster">
                        <widgetAlarmOutput :value="this.getDiagDataCfg('alarmAssignedOutput')" @setOutput="($value) => {this.getDiagDataCfg('alarmAssignedOutput').value = $value}"></widgetAlarmOutput>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <InlineMessage severity="info" class="mt-3 ml-3 mr-3 fg-yellow bg-red" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }" v-if="dialogCheck()">
                        Werte kontrollieren! Nachspeisung ab immer kleiner als Nachspeisung bis und Absenkung ab immer größer als Absenkung bis!
                    </InlineMessage>
                    <widgetDialogFooter :widgetName="`${this.$options.name}_misc`" :department="moduleDepartment.key" :exchange="true" format="json" :saveCheck="dialogCheck()"
                        @diagAbort="diagToggleMisc"
                        @diagSave="saveDiag('misc')"
                        @import="($value) => {this.nodeDataCfg = $value}"
                        :exportObject="this.nodeDataCfg"
                    ></widgetDialogFooter>
                </div>
            </template>
        </Dialog>

        <div v-if="!isLoading">
            <div class="flex justify-content-between align-items-center p-0 flex-wrap">
                <div class="flex justify-content-start align-items-center p-0">
                    <moduleIconEnat :icon="moduleData.metadata.icon" :alarmStatus="alarmStatus" :alarmQueue="alarmQueue" @toogleAlarmOverlay="toogleAlarmOverlay"></moduleIconEnat>
                    <div class="hidden sm:flex mt-1 ml-2">
                        <widget-mini-icon label="Luft+" :value="this.getModuleValue('OUT_AirFill')" v-if="this.getModuleValue('CFG.enablePressureControl').value"></widget-mini-icon>
                        <widget-mini-icon label="Luft-" :value="this.getModuleValue('OUT_AirRelease')" v-if="this.getModuleValue('CFG.enablePressureControl').value"></widget-mini-icon>
                        <widget-mini-icon label="Wasser+" :value="this.getModuleValue('OUT_WaterFill')" v-if="this.getModuleValue('CFG.enableLevelControl').value"></widget-mini-icon>
                        <widget-mini-icon label="Wasser-" :value="this.getModuleValue('OUT_WaterRelease')" v-if="this.getModuleValue('CFG.enableLevelControl').value"></widget-mini-icon>
                        <widget-mini-icon label="Wassermangel" :value="this.getModuleValue('OUT_WM')" color-active="fg-lightCrimson" icon="fi-rr-exclamation"></widget-mini-icon>
                    </div>
                    <div class="flex flex-column mt-3">

                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header :value="this.getModuleValue('OUT_CurrentPressure')" :moduleData="this.moduleData" @showChart="showChart('value1')" :fgColor="fontColor" />
                </div>
            </div>

            <div class="stats flex mt-1">
                <!-- <div class="left flex flex-column"> -->
                <div class="left">
                    <ul class="widget-list">
                        <Widget_rowValues2 :title="'Druck/Inhalt'" :value1="this.getModuleValue('OUT_CurrentPressure')" :value2="this.getModuleValue('OUT_CurrentLevel')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Liter/Gewicht'" :value1="this.getModuleValue('OUT_CurrentLiters')" :value2="this.getModuleValue('OUT_CurrentWeight')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Zeit Luft/Wasser'" :value1="this.getModuleValue('OUT_CurrentTimePressure')" :value2="this.getModuleValue('OUT_CurrentTimeWater')"></Widget_rowValues2>
                    </ul>
                </div>
                <div class="right flex flex-column">
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mt-3">
                            <button-enat title="Einstellungen" subtitle="Alarme, Meldungen" icon="fi fi-rr-settings" fgColor="yellow" bgColor="darkRed"
                                @click="toggleOP($event, 'misc')" >
                            </button-enat>
                        </div>
                    </div>
                </div>
            </div>
            <textbox-enat title="Status" :value="currentStatusStr" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
        </div>
        <Dialog 
            v-model:visible="showDiagName"
            :closable="false"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '450px' }"
            :modal="true"
            class="p-fluid"
        >
            <template #header>
                <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Bezeichnung ändern</span></p>
            </template>
            <div @keydown.esc="hideNameDiag" @keydown.enter="saveNameDiag(changeDiagName)">
                <Panel>
                    <div class="grid p-fluid">
                        <div class="col-12 pl-5 pr-5">
                            <span class="p-float-label mr-2">
                                <InputText id="periodName" ref="periodName" v-model="changeDiagName.value" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                                <label for="periodName" class="mr-2">Bezeichnung</label>
                            </span>
                        </div>
                    </div>
                </Panel>
            </div>
            <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideNameDiag" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveNameDiag(changeDiagName)" />
            </template>
        </Dialog>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <dialog-chart-single :icon="getModuleValue('OUT_CurrentPressure').icon" :node="getModuleValue('OUT_CurrentPressure')" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('OUT_AirFill').icon" :node="getModuleValue('OUT_AirFill')" v-bind:show="showChartAirFill" @chart-close="showChartAirFill = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('OUT_AirRelease').icon" :node="getModuleValue('OUT_AirRelease')" v-bind:show="showChartAirRelease" @chart-close="showChartAirRelease = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('OUT_AirFill').icon" :node="getModuleValue('OUT_WaterFill')" v-bind:show="showChartWaterFill" @chart-close="showChartWaterFill = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('OUT_AirRelease').icon" :node="getModuleValue('OUT_WaterRelease')" v-bind:show="showChartWaterRelease" @chart-close="showChartWaterRelease = false"></dialog-chart-single>

    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';
import helpers from '@/helpers';
import { parseNode, setOpcValueToast, uploadJsonFile, exportJson } from '@/helpers';
import SocketioService from '@/services/socketioService.js';
import Widget_rowValues2 from '@/components/fragments/widget_rowValues2.vue';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
import widgetMiniIcon from '@/components/fragments/widgetMiniIcon.vue';
import widgetAlarmOutput from '@/components/fragments/widgetAlarmOutput.vue';
import widgetDialogFooter from '@/components/fragments/widgetDialogFooter.vue';
import moduleIconEnat from '@/components/fragments/moduleIconEnat.vue';
import widgetTitleBar from '@/components/fragments/widgetTitleBar.vue';
// import widgetTimeSet from '@/components/fragments/widgetTimeSet.vue';

export default defineComponent({
    name: 'widgetHeatingExpansion',
    setup() { },
    components: {
        Widget_rowValues2,
        dialogChartSingle,
        widgetMiniIcon,
        widgetAlarmOutput,
        widgetDialogFooter,
        moduleIconEnat,
        widgetTitleBar,
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
        // nodeEntry: {
        //     handler: function(entry) {
        //         // this.filterParameters();
        //         // if (Array.isArray(entries) && entries.length > 0) {
        //         //     this.loading = false;
        //         // } else {
        //         //     this.loading = true;
        //         // }
        //     },
        //     deep: true
        // }
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getModuleFetched: 'opcua/getModuleFetched',
            isMaster: 'auth/isMaster',
        }),
        isLoading: function() {
            if (!this.moduleData || !this.getModuleFetched(this.moduleHash) || this.loading) return true;
            return false;
        },
        
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.source.server !== null && this.moduleData.source.identifier !== null) {
                    return `${this.moduleData.source.server}@${this.moduleData.source.identifier}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else if (this.moduleData !== null && this.moduleData.metadata.label) {
                return this.moduleData.metadata.label;
            } else {
                return store.getters.getNoval;
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                let background = `bg-${this.content.style.background}`;
                if (this.alarmStatus > 0) background = 'alarm';
                return `box ptrn-${this.content.style.pattern} ${background} fg-${this.content.style.foreground}`;
            } else {
                return '';
            }
        },
        fontColor: function () {
            if (this.content.style && this.content.style.foreground) {
                return `fg-${this.content.style.foreground}`;
            }
            return 'gray';
        },
        icon: function () {
            if (this.alarmStatus === 0) {
                return this.moduleData.metadata.icon
            } else {
                return `${this.moduleData.metadata.icon} fg-red pulse-loop cursor-pointer bg-lightCrimson`;
            }
        },
        alarmEntriesCount: function () {
            if (this.getModuleValue(`AlarmEntriesCount`).value > 0) {
                return this.getModuleValue(`AlarmEntriesCount`).value;
            } else {
                return 0;
            }
        },
        alarmStatus: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value <= 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmQueue: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value === 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmList: function () {
            let activeAlarms = [];
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value !== 0) {
                    const alarm = {
                        label: this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value,
                        state: this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value
                    }
                    activeAlarms.push(alarm);
                }
            }
            return activeAlarms;
        },
        alarmTicker: function () {
            let alarmStr = '';
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value > 0) {
                    alarmStr += `🔴 ${this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value} `;
                }
            }
            return alarmStr;
        },
        orientationStr1: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 1);
        },
        orientationStr2: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 2);
        },
        currentStatusStr: function () {
            return this.getModuleValue('OUT_StatusStr').value;
        },
        currentPositionFloat1: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_1_Aktuell').value);
        },
        currentPositionFloat2: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_2_Aktuell').value);
        },
        periodIndex: function () {
            return this.getModuleValue('OUT_CurrentPeriod.periodIndex').value;
        },
        periodCheck: function () {
            if (this.nodeDataPeriods.length > 0) {
                let cnt = 0;
                this.nodeDataPeriods.forEach((period) => {
                    const result = period.find(entry => entry.displayName === 'periodEnable');
                    if (result) {
                        if (result.value === true) cnt+=1;
                    }
                });
                if (cnt > 0) return false;
            }
            return true;
        },
    },
    data() {
        return {
            loading: false,
            showChart1: false,
            showChartWaterFill: false,
            showChartWaterRelease: false,
            showChartAirFill: false,
            showChartAirRelease: false,
            saveWait: false,
            dialogLoading: false,
            entryDialog: false,
            valveDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            nodeData: [],
            nodeDataOld: [],
            nodeDataCfg: [],
            nodeDataCfgOld: [],
            nodeDataPeriods: [],
            nodeDataPeriodsOld: [],
            diagPeriod: 0,
            showDiagName: false,
            currentGrp: {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            },
            node: null,
            lala: null,
            showDiagPeriods: false,
            showDiagMisc: false,
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'VL-Perioden',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'periods');
                    },
                },
                {
                    label: 'Allgemeine Einstellungen',
                    icon: 'fi fi-rr-settings-sliders',
                    command: () => {
                        // this.editValve(this.content);
                        this.toggleOP(null, 'misc');
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Widget Config',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        if (!this.getModuleFetched(this.moduleHash)) {
            this.loadModule(this.connectedModule).then((module) => {
                const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                    if (!err && response) {
                        if (Array.isArray(response) && response.length > 0) {
                            this.setNodesFull(response);
                        }
                    }
                }).then(() => { 
                    if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                    this.setModuleFetched(this.moduleHash);
                    this.loading = false;
                });
            });
        } else {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            loadModule: 'opcua/loadModule',
            setNodesFull: 'opcua/setNodesFull',
            setModuleFetched: 'opcua/setModuleFetched',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        getModuleValue(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            return this.getNode(key);
        },
        getModuleValueStr(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            const node = this.getNode(key);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        getDiagData(key) {
            if (this.nodeData.length > 0) {
                const result = this.nodeData.find(entry => entry.key2 === key);
                return result;
            }
            return null;
        },
        getDiagDataPeriods(index, displayName) {
            if (this.nodeDataPeriods.length > 0 && index >= 0) {
                const result = this.nodeDataPeriods[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataCfg(displayName) {
            if (this.nodeDataCfg.length > 0 && displayName) {
                const result = this.nodeDataCfg.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        watchCreatedLayout() {
            // this.loading = true;
        },
        watchLayout() {
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        clearVals() {
            this.nodeDataCfg = [];
            this.nodeDataCfgOld = [];
            this.nodeDataPeriods = [];
            this.nodeDataPeriodsOld = [];
            this.saveWait = false;
            this.dialogLoading = false;
            this.diagPeriod = 0;
        },
        updateMap() {
            // this.node = helpers.mapNodes(_.cloneDeep(this.nodeEntry.children), null);
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    this.node.set(key, {...child, value: child.value});
                }
            }
        },
        currentMap() {
            let map = new Map();
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    map.set(key, {...child, value: child.value});
                }
                return map;
            }
            return null;
        },
        getParameter(lvl1, lvl2, index) {
            let parameter = null;
            if (!lvl1) return false;
            if (!lvl2) lvl2 = '';
            if (!isNaN(index)) {
                parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
            } else {
                parameter = this.node.get(lvl1 + lvl2);
            }
            return parameter;
        },
        returnChild(childStr) {
            if (typeof childStr == 'string' && this.nodeEntry !== undefined) {
                return helpers.returnChild(this.nodeEntry, childStr);
            } else {
                return null;
            }
        },
        returnParameter(node, key, parameter) {
            return helpers.returnParameter(node, key, parameter);
        },
        returnParameterValue(node, key, parameter, unit) {
            return helpers.returnParameterValue(node, key, parameter, unit);
        },
        timeDialog(time) {
            console.log(time);
        },
        toogleAlarmOverlay(event) {
            this.$refs.alarmOverlay.toggle(event);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.this.getModuleValueStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleOP(event, grp) {
            if (grp === 'periods') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG.periods*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            let tmpNodes = [];
                            tmpNodes = JSON.parse(JSON.stringify(response));
                            tmpNodes.forEach((entry) => {
                                if (entry.child1) {
                                    const child = JSON.parse(entry.child1)
                                    if (child.index >= 0) {
                                        if (!Array.isArray(this.nodeDataPeriods[child.index])) {
                                            this.nodeDataPeriods[child.index] = [];
                                        }
                                        this.nodeDataPeriods[child.index].push(entry);
                                    }
                                }
                                // console.log(entry);
                            });
                            this.nodeDataPeriodsOld = JSON.parse(JSON.stringify(this.nodeDataPeriods));
                            console.log(this.nodeDataPeriods);
                            console.log(this.nodeDataPeriodsOld);
                            // console.log(this.getDiagDataHzg(0, 'periodName'));
                        }
                    }
                }).then(() => {
                    this.showDiagPeriods = true;
                });        
            } else if (grp === 'periodsVents') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.Perioden_Lueftung*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            let tmpNodes = [];
                            tmpNodes = JSON.parse(JSON.stringify(response));
                            tmpNodes.forEach((entry) => {
                                if (entry.child0) {
                                    const child = JSON.parse(entry.child0)
                                    if (child.index >= 0) {
                                        if (!Array.isArray(this.nodeDataLft[child.index])) {
                                            this.nodeDataLft[child.index] = [];
                                        }
                                        this.nodeDataLft[child.index].push(entry);
                                    }
                                }
                                // console.log(entry);
                            });
                            this.nodeDataLftOld = JSON.parse(JSON.stringify(this.nodeDataLft));
                            console.log(this.nodeDataLft);
                            console.log(this.nodeDataLftOld);
                            // console.log(this.getDiagDataHzg(0, 'periodName'));
                        }
                    }
                }).then(() => {
                    this.showDiagVents = true;
                }); 
            } else if (grp === 'misc') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataCfg = JSON.parse(JSON.stringify(response));
                            this.nodeDataCfgOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataCfg);
                            console.log(this.nodeDataCfgOld);
                            // console.log(this.getDiagDataHzg(0, 'periodName'));
                        }
                }).then(() => {
                    this.showDiagMisc = true;
                }); 
            }
        },
        diagTogglePeriods() {
            if (this.showDiagPeriods) {
                this.showDiagPeriods = false;
            } else {
                this.showDiagPeriods = true;
            }
            this.hideOP();
        },
        diagToggleMisc() {
            if (this.showDiagMisc) {
                this.showDiagMisc = false;
            } else {
                this.showDiagMisc = true;
            }
            this.hideOP();
        },
        hideOP() {
            this.clearVals();
        },
        saveDiag(diag) {
            if (diag) {
                const saveArray = [];
                this.dialogLoading = true;
                this.saveWait = true;

                if (diag === 'periods' && !this.periodCheck) {
                    console.log(this.nodeDataPeriods);
                    console.log(this.nodeDataPeriodsOld);
                    const flatHzg = this.nodeDataPeriods.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataPeriodsOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'periodsLft') {
                    console.log(this.nodeDataLft);
                    console.log(this.nodeDataLftOld);
                    const flatHzg = this.nodeDataLft.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataLftOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'misc' && !this.dialogCheck()) {
                    console.log(this.nodeDataCfg);
                    console.log(this.nodeDataCfgOld);
                    this.nodeDataCfg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = this.nodeDataCfgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                }
                
                // console.log(saveArray);
                if (saveArray.length > 0) {
                    SocketioService.setOpcValue(saveArray, (err, response) => {
                        if (err) {
                            console.log(err);
                        } else {
                            console.log(response);
                            setOpcValueToast(response, this.$root);
                            this.dialogLoading = false;
                            if (diag === 'periods') this.showDiagPeriods = false;
                            if (diag === 'misc') this.showDiagMisc = false;
                            this.hideOP();
                        }
                    });
                } else {
                    if (diag === 'periods' && !this.periodCheck) {
                        this.saveDiagNoChanges();
                        this.showDiagPeriods = false;
                    } else if (diag === 'periods' && this.periodCheck) {
                        this.saveDiagNoPeriods();
                    } else if (diag === 'misc') {
                        this.saveDiagNoChanges();
                        this.showDiagMisc = false;
                    }
                }
            }
        },
        saveDiagNoChanges() {
            setOpcValueToast(null, this.$root);
            this.dialogLoading = false;
            this.hideOP();
        },
        saveDiagNoPeriods() {
            this.$toast.add({
                severity: 'warn',
                summary: 'Eine Periode aktivieren!',
                detail: 'Es muss zumindest eine Periode aktiviert werden.',
                life: 3000,
            });
            this.dialogLoading = false;
            this.saveWait = false;
        },
        toogleNameDiag(name) {
            this.changeDiagName = JSON.parse(JSON.stringify(name));
            this.showDiagName = true;
        },
        hideNameDiag() {
            this.changeDiagName = null;
            this.showDiagName = false;
        },
        saveNameDiag(node) {
            console.log(node);
            if (node.child0) {
                const child = JSON.parse(node.child0);
                if (child.index >= 0) {
                    console.log(child.index)
                    let obj2 = null;
                    obj2 = this.nodeDataPeriods[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagName.value === 'string' && this.changeDiagName.value.length > 0) {
                            obj2.value = this.changeDiagName.value;
                        }
                        console.log(obj2);
                    }
                }
            }
            this.hideNameDiag();
        },
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            this.ioDialogData = JSON.parse(JSON.stringify(ioData));
            this.ioObj = JSON.parse(JSON.stringify(this.moduleData));
            this.entryDialog = true;
        },
        saveDialog() {
            this.submitted = true;
            if (this.ioDialogData.i) {
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        editValve(ioData) {
            console.log(ioData);
        },
        hideValveDialog() {
            this.valveDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        showChart(value) {
            if (value === 'value1') this.showChart1 = true;
            else if (value === 'airfill') this.showChartAirFill = true;
            else if (value === 'airrelease') this.showChartAirRelease = true;
            else if (value === 'waterfill') this.showChartWaterFill = true;
            else if (value === 'waterrelease') this.showChartWaterRelase = true;
        },
        calcLux(val, mode) {
            return helpers.calcLight(val, mode);
        },
        focusDiag(event) {
            this.periodDiagDelay(event);
        },
        periodDiagDelay(event) {
            setTimeout(() => {
                this.diagPeriod = this.periodIndex;
                this.focusElement(event);
            }, 300);
        },
        dialogCheck: function () {
            if (this.nodeDataCfg.length > 0) {
                let pressureCheck, waterCheck;
                if (this.getDiagDataCfg('enablePressureControl').value && (this.getDiagDataCfg('pressureSetOffPlus').value > this.getDiagDataCfg('pressureSetOnPlus').value) && (this.getDiagDataCfg('pressureSetOnMinus').value > this.getDiagDataCfg('pressureSetOffMinus').value)) {
                    pressureCheck = true;
                } else {
                    pressureCheck = false;
                }
                if (this.getDiagDataCfg('enableLevelControl').value && (this.getDiagDataCfg('waterSetOffPlus').value > this.getDiagDataCfg('waterSetOnPlus').value) && (this.getDiagDataCfg('waterSetOnMinus').value > this.getDiagDataCfg('waterSetOffMinus').value)) {
                    waterCheck = true;
                } else {
                    waterCheck = false;
                }
                if ((!this.getDiagDataCfg('enablePressureControl').value || pressureCheck) && (!this.getDiagDataCfg('enableLevelControl').value || waterCheck)) return false;
            }
            return true;
        },
        uploadJsonFileMisc(ev) {
            uploadJsonFile(ev, this.$root, this.$options.name, this.nodeDataCfg);
        },
        exportJsonFile(ioData) {
            exportJson(ioData, this.$options.name);
        },
    },
});
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}

.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for <2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.pulse-enter-active {
    animation: pulse 1s;
}

.pulse-leave-active {
    animation: pulse 0.5s;
}

.fade-in-enter-active {
    animation: fadeIn linear 2s;
}

.fade-in-leave-active {
    animation: fadeOut linear 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
        // opacity: 0;
    }
    50% {
        // opacity: 1;
        background-color: rgba(117, 18, 0, 0.5);
    }
    100% {
        // opacity: 0;
    }
}

.alarm {
    animation: fadeInOut 1s infinite;
    -webkit-animation: fadeInOut 1s infinite;
}

.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}

.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}

.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}

.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}

.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}

.text-normal {
    line-height: 0;
}
// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}

.list-leave-active {
    animation: list 0.5s;
}

@keyframes list {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.p-togglebutton {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.2rem;
    margin: 0;
}
</style>